h4, h5 {
  font-size: 3rem;
}
span {
  font-weight: normal;
}

.payment{
  align-items: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 80vh;
  justify-content: center;
  margin: 2rem auto;
  transition: 0.3s;
  width: 50%;
}
.payment:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.ButtonBack {
  background-color: #333333;
  color: white;
  display: block;
  margin: 2rem auto;
  padding: 0.8rem;
  width: 150px;
  text-align: center;
  text-decoration: none;
}

 @media screen and (max-width: 900px) {
  .payment{
    width: 70%;
  }
 }

 @media screen and (max-width: 700px) {
  .payment{
    width: 90%;
  }
  h4, h5 {
    font-size: 2.4rem;
  }
 }