
button {
  transition: transform 300ms linear;
}

button:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.Infraccion {
  align-content: center;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  position: relative;
}

.Infraccion-header {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  padding: 1rem;
}

.numero {
  color: crimson;
  font-weight: 700;
  font-size: 1.9rem;
}

.fecha {
  font-size: 1.9rem;
}

.numero::before {
  content: "N°: ";
}

.Infraccion-body {
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 1rem;
}


.direccion,
.tipo {
  font-size: 2rem;
  margin-top: 1rem;
}

.vehiculo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.vehiculo i {
  color: crimson;
  font-weight: bold;
}

.Infraccion-acciones {
  display: flex;
  gap: 1.2rem;
  justify-content: center;
  margin: 2.4rem auto;
}

.Infraccion-acciones .Infraccion-button {
  border-radius: 50%;
  font-size: 1.8rem;
  height: 60px;
  padding: 0.5rem;
  width: 60px;
}
.Infraccion-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  transition: visibility 1s ease-in;
  margin-bottom: 3rem;
}

.Infraccion-images img {
  object-fit: cover;
  width: 300px;
}

.Infraccion-videos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  transition: visibility 1s ease-in;

}

.Infraccion-videos img {
  object-fit: cover;
  max-width: 500px;
  margin: 0 auto 3rem;
  border: 6px dashed #dd0000;
}

.Show {
  visibility: visible;
}

.Hide {
  visibility: hidden;
}

.Infraccion-acciones div :nth-child(1) {
  color: #f4f4f4;
  background-color: #BF0025;
  transition: transform 300ms linear;
}

.Infraccion-acciones div :nth-child(2) {
  color: #000;
  margin-left: 0.6rem;
  font-size: 1.4rem;
}

.Infraccion-acciones div :nth-child(2):hover {
  cursor: pointer;
}

.Infraccion-acciones small {
  background-color: #fff;
}

.ButtonBack {
  background-color: #333333;
  color: white;
  display: block;
  margin: 2rem auto;
  padding: 0.8rem;
  width: 150px;
}

.ButtonNew {
  background-color: #BF0025;
  color: white;
  display: block;
  margin: 1rem auto;
  margin-bottom: 8rem;
  padding: 0.8rem;
  width: 180px;
}

input,
button{
  z-index: 10;
  font-size: 1.8rem;
  font-family: 'Fira Sans', sans-serif;
}

.alert-text {
  font-family: 'Fira Sans', sans-serif !important;
  font-weight: 400 !important;
}

@media screen and (max-width: 1100px) {
  .Infraccion {
    padding: 1.2rem;
  }
}

@media screen and (max-width: 414px) {
  .Footer {
    bottom: 0;
  }

}