.Main {
  background-color: rgba(211, 211, 211, 0.37);
  margin: 0 auto;
  padding: 0.1px;
  min-height: 500px;
  margin-top: 2rem;
}

.Query {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5rem auto;
  font-size: 2rem;
}

.video-container {
  height: inherit;
  width: inherit;
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  opacity: 0.3;
}

/* Just styling the content of the div, the *magic* in the previous rules */
.video-container .caption {
  z-index: 1;
  position: relative;
  text-align: center;
  color: #dc0000;
  padding: 10px;
}

.Form {
  align-content: center;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 4rem 2rem;
  justify-content: center;
  width: 35%;
  transition: display 3s ease-out;
}

.FormInput {
  align-self: flex-start;
  height: 3rem;
  width: 60%;
}

.FormLabel {
  font-size: 2rem;
}

.FormButton {
  background-color: crimson;
  border: none;
  color: white;
  display: block;
  padding: 1rem;
  margin: 2rem auto;
  text-align: center;
  width: 200px;
}

.Infracciones {
  align-content: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem auto;
}

.Loading {
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
}

.Show {
  display: flex;
}

.Hide {
  display: none;
}

.Infracciones {
  width: 60%;
  margin: 1rem auto;
}

.title-infracciones {
  font-size: 2.4rem;
  text-decoration: underline;
}

.Form #dominio {
  text-transform: uppercase;
}
input, button {
  z-index: 10;
  font-size: 1.8rem;
  font-family: 'Fira Sans', sans-serif;
}

@media screen and (max-width: 1100px) {
  .Query {
    margin: 1rem auto;
    font-size: 1.8rem;
    text-align: center;
  }

  .Form {
    width: 50%;
    margin: 0 auto;
  }

  .FormInput,
  .FormLabel {
    width: 60%;
    font-size: 1.8rem;
  }

  .FormButton {
    width: 170px;
  }

  .Infracciones {
    width: 90%;
    margin: 1rem auto;
  }
}

@media screen and (max-width: 414px) {
  .Query {
    margin: 1rem auto;
    font-size: 1.5rem;
    text-align: center;
  }

  .Form {
    width: 70%;
    margin: 0 auto;
  }

  .FormInput,
  .FormLabel {
    width: 100%;
    font-size: 1.5rem;
  }

  .FormButton {
    width: 170px;
  }
}