.Header{
  background-color: #BF0025;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  color: white;
  opacity: 0.9;
  height: 40px;
  text-align: center;
  margin: 0;
  padding: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.Header .title{
  text-align: center;
}
.Header img.bg {
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 380px;
}

.Header img.logo {
  position: absolute;
  width: 5%;
  left: 3%;
  top: 0%;
}

.Header .title{
  color: white;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  line-height: 4rem;
  margin: 0;
}
@media screen and (max-width: 1100px) {
  .Header .title{
    font-size: 2rem;
    
  }
  .Header img.logo {
    width: 8%;
    left: 3%;
    top: 0%;
  }
}

@media screen and (max-width: 414px) {
  .Header .title{
    font-size: 1.4rem;
  }
    
  .Header img.logo {
      width: 15%;
      left: 3%;
      top: 0.5%;
    }
}